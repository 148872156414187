<template>
  <div class="dictionaries app-container">
    <div :class="{ mr10: detailVisible, w50: detailVisible, w100: !detailVisible }">
      <pageCard
        :formFile="formFile1"
        :tableCol="tableCol1"
        url="/externaladmin/systemService/dictionary/list"
        :permission="permission1"
        @getListId="getDictId"
        @getDicType="getDicType"
      ></pageCard>
    </div>
    <div v-if="detailVisible" :class="{ w50: detailVisible }">
      <pageCard
        title="字典详情"
        :formFile="formFile2"
        url="/externaladmin/systemService/dictionaryItem/list"
        :tableCol="tableCol"
        :dictId="dictId"
        :permission="permission2"
      ></pageCard>
    </div>
  </div>
</template>

<script>
import pageCard from './module/pageCard.vue'
import { formFile1, formFile2, tableCol1, tableCol2, tableCol3 } from './module/formFiels.js'
export default {
  components: {
    pageCard
  },
  props: {},
  data() {
    return {
      detailVisible: false,
      formFile1,
      formFile2,
      tableCol1,
      tableCol2,
      tableCol3,
      tableCol: {},
      dictId: undefined,
      dicType: '',
      permission1: {
        add: this.checkPermission(['externaladmin:dictionary:create']),
        edit: this.checkPermission(['externaladmin:dictionary:update']),
        delete: this.checkPermission(['externaladmin:dictionary:delete'])
      },
      permission2: {
        add: this.checkPermission(['externaladmin:dictionaryItem:create']),
        edit: this.checkPermission(['externaladmin:dictionaryItem:update']),
        delete: this.checkPermission(['externaladmin:dictionaryItem:delete']),
        enable: this.checkPermission(['externaladmin:dictionaryItem:enable'])
      }
    }
  },
  watch: {
    dictId: {
      handler(val) {
        this.detailVisible = !!val
      },
      immediate: true
    },
    dicType: {
      handler(val) {
        this.tableCol = val != 'bankCategory' ? this.tableCol2 : this.tableCol3
      },
      immediate: true
    }
  },
  methods: {
    getDictId(val) {
      this.dictId = val
    },
    getDicType(val) {
      this.dicType = val
    }
  }
}
</script>

<style scoped lang="scss">
.dictionaries {
  display: flex;
  .w50 {
    width: 50%;
  }
  .w100 {
    width: 100%;
  }
}
</style>
