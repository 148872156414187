<template>
  <div class="pageCard">
    <header>
      <div class="header-title">{{ title }}</div>
      <el-button v-if="optionBtn.add" @click="addAndEditHandle('add')" size="small">新增</el-button>
    </header>
    <BaseForm :cols="formFile" @change="formChange"></BaseForm>
    <CommonTable
      height="700px"
      :selection="false"
      :cols="tableCol"
      :infoData="data"
      :tableLoading="tableLoading"
      @row-click="changeDetailPage"
    >
      <template #statusSlot="{ row }">
        {{ row.isEnabled == 1 ? '已启用' : '已禁用' }}
      </template>

      <template #operationSlot="{ row }">
        <ColorTextBtn class="line" @click="addAndEditHandle('edit', row)" v-if="optionBtn.edit">编辑</ColorTextBtn>
        <el-popover placement="top" width="160" trigger="click" v-model="row.visible">
          <p>确定删除吗这条数据吗？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
            <el-button type="primary" size="mini" :loading="loading" @click="delHandle(row)">确定</el-button>
          </div>
          <ColorTextBtn slot="reference" type="danger" v-if="optionBtn.delete">删除</ColorTextBtn>
        </el-popover>
      </template>
      <template #operSlot="{ row }">
        <ColorTextBtn class="line" @click="getUpdate(row)" v-if="canEnable(row, 'enable')">启用</ColorTextBtn>
        <ColorTextBtn class="line" @click="getUpdate(row)" v-if="canDisable(row, 'disable')">禁用</ColorTextBtn>
        <ColorTextBtn class="line" @click="addAndEditHandle('edit', row)" v-if="optionBtn.edit">编辑</ColorTextBtn>
        <el-popover placement="top" width="160" trigger="click" v-model="row.visible">
          <p>确定删除吗这条数据吗？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
            <el-button type="primary" size="mini" :loading="loading" @click="delHandle(row)">确定</el-button>
          </div>
          <ColorTextBtn slot="reference" type="danger" v-if="optionBtn.delete">删除</ColorTextBtn>
        </el-popover>
      </template>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <BaseForm :cols="dialogFormFile" :form="dialogForm" ref="dialogFormRef" label-width="100px">
        <template #itemValueSlot="{ scoped: { prop } }">
          <el-input v-model.number="dialogForm[prop]"></el-input>
        </template>
        <template #sortSlot="{ scoped: { prop } }">
          <el-input-number v-model="dialogForm[prop]" controls-position="right" :min="1" :max="999"></el-input-number>
        </template>
      </BaseForm>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="clickHandle" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import initDataMixin from '@/mixins/initData'
import { addAndEditForm1, addAndEditForm2 } from './formFiels'
import {
  dicAddList,
  getDicListById,
  dicUpdateList,
  dicDeleteList,
  getDicItemById,
  dicItemCreate,
  dicItemUpdate,
  dicItemdelete,
  updateBankStatus
} from '@/api/dicApi'
export default {
  mixins: [initDataMixin],
  components: {},
  props: {
    permission: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: '字典列表'
    },
    formFile: {
      type: Array,
      required: true
    },
    tableCol: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      default: '/externaladmin/systemService/dictionary/list'
    },
    dictId: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      addAndEditForm1,
      addAndEditForm2,
      dialogForm: {},
      dialogVisible: false,
      loading: false,
      handleType: {
        type: '',
        handle: ''
      },
      query: this.title === '字典详情' ? { dictId: this.dictId } : {}
    }
  },
  computed: {
    canEnable() {
      return (row) => {
        console.log('我被', this.optionBtn.enable)
        return row.isEnabled == 0 && this.optionBtn.enable
      }
    },
    canDisable() {
      return (row) => {
        return row.isEnabled == 1 && this.optionBtn.enable
      }
    },
    dialogTitle() {
      return `${this.handleType.handle}${this.handleType.type}`
    },
    dialogFormFile() {
      if (this.handleType.type === '字典列表') {
        return this.addAndEditForm1
      } else {
        return this.addAndEditForm2
      }
    },
    optionBtn() {
      return this.permission
    }
  },
  watch: {
    dictId(newVal) {
      if (this.title === '字典详情') {
        this.query.dictId = newVal
        this.init()
      }
    }
  },
  methods: {
    async getUpdate(row, type) {
      try {
        const data = {
          id: row.id,
          isEnabled: row.isEnabled == 0 ? 1 : 0
        }
        const { code, detail } = await updateBankStatus(data)
        const message = type == 'enable' ? '启用成功' : '禁用成功'
        if (!$SUC(code)) {
          this.init()
          this.$message.success(message)
        }
      } catch (error) {}
    },
    // 搜索框的事件
    formChange(val) {
      this.query = val
      this.init()
    },
    // 点击新增或编辑的事件
    async addAndEditHandle(type, row) {
      this.handleType.type = this.title
      if (type === 'add') {
        this.dialogForm = {}
        this.handleType.handle = '新增'
      } else {
        this.handleType.handle = '编辑'
        if (this.title === '字典列表') {
          const { detail } = await getDicListById({ id: row.id })
          this.dialogForm = detail
        } else {
          const { detail } = await getDicItemById({ id: row.id })
          this.dialogForm = detail
        }
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs['dialogFormRef'].clearValidate()
      })
    },
    // 点击确认删除触发事件
    async delHandle(row) {
      this.loading = true
      try {
        if (this.title === '字典列表') {
          const { code } = await dicDeleteList({ idList: [row.id] })
          this.judge(code)
        } else {
          const { code } = await dicItemdelete({ idList: [row.id] })
          this.judge(code)
        }
      } catch {}
      this.loading = false
    },
    // 点击弹窗中的确定后触发的事件
    async clickHandle() {
      try {
        await this.$refs['dialogFormRef'].validate()
        if (this.handleType.type === '字典列表') {
          if (this.handleType.handle === '新增') {
            const { code } = await dicAddList(this.dialogForm)
            this.judge(code)
          } else {
            const { code } = await dicUpdateList(this.dialogForm)
            this.judge(code)
          }
        } else {
          if (this.handleType.handle === '新增') {
            this.dialogForm.dictId = this.dictId
            const { code } = await dicItemCreate(this.dialogForm)
            this.judge(code)
          } else {
            const { code } = await dicItemUpdate(this.dialogForm)
            this.judge(code)
          }
        }
      } catch {
        return
      }
      this.loading = false
      this.dialogVisible = false
    },
    judge(code) {
      if (code === 0) {
        this.$message.success('操作成功')
        this.init()
      } else {
        this.$message.error('操作失败')
      }
    },
    // 点击字典列表行触发
    changeDetailPage(row, column) {
      if (this.title === '字典列表') {
        if (column.label === '名称' || column.label === '描述') {
          this.$emit('getListId', row.id)
          this.$emit('getDicType', row.dictType)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pageCard {
  //   flex: 1;
  height: 100%;
  box-shadow: 0px 0px 5px 2px rgba($color: #000000, $alpha: 0.1);
  header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    .header-title {
      line-height: 32px;
    }
    .header-button {
      text-align: center;
    }
  }
  .line::after {
    content: '|';
    line-height: 1;
    font-size: 12px;
    margin: 0 8px 0 10px;
    display: inline-block;
    transform: scaleX(0.5);
    color: #dcdee0;
  }
}
</style>
